import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export const loginUser = async (
  email,
  password,
  fn,
  setDispatch,
  setUser,
  navigate,
  capChaRef,
  errorState
) => {
  const auth = getAuth();
  try {
    const credential = await signInWithEmailAndPassword(auth, email, password);
    const baseUrl =
      process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
    const { user } = credential;
    if (!user.emailVerified) {
      fn({
        type: "regError",
        payload: "Sajnos még nem erősítetted meg a regisztrációdat",
      });
      return;
    }
    const token = await user.getIdToken();
    const response = await fetch(`${baseUrl}auth`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const status = response.status;
    if (status === 200) {
      await capChaRef.current.execute();
      if (errorState.errors.regError) {
        return;
      }
      fn({ type: "reset" });
      setDispatch(setUser(true));
      navigate("/uploadfile");
    }
  } catch (err) {
    const code = err.code;
    const message =
      code === "auth/user-not-found"
        ? "Sajnos még nem regisztráltál"
        : "Hiba a belépés során!!";
    fn({
      type: "regError",
      payload: message,
    });
  }
};
