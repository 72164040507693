import React, { Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLangOne, addLangTwo } from "../../utils/reducer";
import { addLangueOne, addLangueTwo } from "../../utils/updatemodel";
const TableHead = ({ rows, state, isModal }) => {
  const dispatch = useDispatch();
  const comletedRows = useCallback(() => {
    const t = rows.filter((e) => e.lang_one !== "" && e.lang_two !== "");
    return t.length;
  }, [rows]);
  const trows = comletedRows();
  const { upload } = useSelector((state) => state.update);
  const flags = [
    { lg: "HUN", img: "🇭🇺" },
    { lg: "ENG", img: "🇬🇧" },
    { lg: "GER", img: "🇩🇪" },
  ];
  const optionmaker = (opt) => {
    if (upload) {
      const def =
        opt === 1
          ? flags.find((flag) => flag.lg === upload.lang_1)
          : flags.find((flag) => flag.lg === upload.lang_2);
      return (
        <Fragment>
          <option value={def.lg}>{def.img}</option>
          {flags
            .filter((f) => f.lg !== def.lg)
            .map((e) => (
              <option value={e.lg} key={e.lg}>
                {e.img}
              </option>
            ))}
        </Fragment>
      );
    }
  };
  return (
    <div>
      <div
        className="shadow overflow-hidden border-b border-gray-200 table-top row-grid"
        id="table-top-grid"
      >
        <div className="top-column inner_div">
          <select
            className="select"
            onChange={(e) =>
              dispatch(
                !isModal
                  ? addLangOne(e.target.value)
                  : addLangueOne(e.target.value)
              )
            }
          >
            {!isModal ? (
              <Fragment>
                <option defaultValue></option>
                <option value="HUN">🇭🇺</option>
                <option value="ENG">🇬🇧</option>
                <option value="GER">🇩🇪</option>
              </Fragment>
            ) : (
              optionmaker(1)
            )}
          </select>

          <span>Elölap</span>
        </div>

        <div className="top-column overflow-hidden inner_div">
          <select
            className="select"
            onChange={(e) =>
              dispatch(
                !isModal
                  ? addLangTwo(e.target.value)
                  : addLangueTwo(e.target.value)
              )
            }
          >
            {!isModal ? (
              <Fragment>
                <option defaultValue></option>
                <option value="HUN">🇭🇺</option>
                <option value="ENG">🇬🇧</option>
                <option value="GER">🇩🇪</option>
              </Fragment>
            ) : (
              optionmaker(2)
            )}
          </select>
          <span>Hátlap</span>
        </div>
        <div className="inn-word">
          Összesen: {state !== null ? state.length + trows : trows} szó
        </div>
      </div>
    </div>
  );
};

export default TableHead;
