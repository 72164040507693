import React, { useCallback } from "react";

import { removeItem } from "../../utils/reducer";
import { useDispatch } from "react-redux";
import Bin from "../utility/utility-icons/bin";

import { modifyItemOne, modifyItTwo } from "../../utils/reducer";
const TableRow = ({ items, modalState, imageModal }) => {
  const dispatch = useDispatch();

  const modifiSelectedRowOne = useCallback((item, did) => {
    dispatch(modifyItemOne({ item, did }));
  }, []);

  const modifiSelectedRowTwo = useCallback((item, did) => {
    dispatch(modifyItTwo({ item, did }));
  }, []);

  const remove = () => {
    dispatch(removeItem(items.did));
  };

  return (
    <div className="row-grid w-full overflow-hidden ">
      <div className="whitespace-nowrap row-inner ">
        <div
          className={
            items.lang_one.length > 0
              ? "flex items-center overflow-hidden"
              : "flex items-center overflow-hidden input-inner-error"
          }
        >
          <input
            type="text"
            value={items.lang_one || ""}
            disabled={modalState || imageModal ? true : false}
            onChange={(e) => modifiSelectedRowOne(e.target.value, items.did)}
            placeholder={items.lang_one && items.lang_one}
            className="m-1 rounded w-full h-10 py-2 px-3 focus:outline-none focus:shadow-outline input-inner"
          />
        </div>
      </div>
      <div className="whitespace-nowrap row-inner">
        <div
          className={
            items.lang_two.length > 0
              ? "flex items-center overflow-hidden"
              : "flex items-center overflow-hidden input-inner-error"
          }
        >
          <input
            type="text"
            value={items.lang_two || ""}
            placeholder={items.lang_two && items.lang_two}
            onChange={(e) => modifiSelectedRowTwo(e.target.value, items.did)}
            disabled={modalState || imageModal ? true : false}
            className="m-1 rounded w-full h-10 py-2 px-3 focus:outline-none focus:shadow-outline input-inner input-inner-error"
          />
        </div>
      </div>
      <div className="whitespace-nowrap flex items-center bin-collumn">
        <button
          type="button"
          disabled={modalState}
          onClick={() => remove()}
          className="outline-none"
        >
          <Bin width="10pt" />
        </button>
      </div>
    </div>
  );
};
export default TableRow;
