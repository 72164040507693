export const paks = [
  {
    pid: "64646466",
    name: " simply dummy text of the printing and typesetting",
    code: "fs342",
    url: "https://images.unsplash.com/photo-1509062522246-3755977927d7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1104&q=80",
    length: 67,
    fakeState: [
      ["kutya", "macska"],
      ["roka", "farkas"],
      ["nyul", "eger"],
      ["hollo", "rigo"],
    ],
  },
  {
    pid: "5555666",
    name: " Contrary to popular belief, Lorem Ipsum is not simply random text",
    code: "ff642",
    url: "https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    length: 76,
    fakeState: [
      ["c#", "javascript"],
      ["python", "php"],
      ["css", "html"],
      ["golang", "r"],
    ],
  },
  {
    pid: "5353535355",
    name: "only five centuries, but also the leap into electronic",
    code: "ft562",
    url: "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    length: 4,
    fakeState: [
      ["john wick", "ridick"],
      ["infinity", "mad max"],
      ["godfather", "zerozerozero"],
      ["fly", "valami amerika"],
    ],
  },
  {
    pid: "353535",
    name: " passage, and going through the cites of the word in classica",
    code: "fu342",
    url: "https://images.unsplash.com/photo-1599008633840-052c7f756385?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    length: 12,
    fakeState: [
      ["redux", "useState"],
      ["useEffect", "useMemo"],
      ["useReducer", "memo"],
      ["useContext", "reducer"],
    ],
  },
  {
    pid: "55335",
    name: " software like Aldus PageMaker including versions of Lorem Ipsum",
    code: "fl342",
    url: "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1934&q=80",
    length: 1008,
    fakeState: [
      ["postges", "mongodb"],
      ["mssql", "mysql"],
      ["redis", "firebase"],
      ["aws", "firestore"],
    ],
  },
  {
    pid: "44444",
    name: "discovered the undoubtable source. Lorem Ipsum",
    code: "fsi942",
    url: "https://images.unsplash.com/photo-1606636660488-16a8646f012c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80",
    length: 90,
    fakeState: [
      ["kutya", "gyik"],
      ["roka", "farkas"],
      ["madar", "kigyo"],
      ["hollo", "rigo"],
    ],
  },
  {
    pid: "232232335",
    name: " Latin words, combined with a handful of model sentence",
    code: "fp142",
    url: "https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    length: 100,
    fakeState: [
      ["kek", "zold"],
      ["piros", "sarga"],
      ["narancs", "feher"],
      ["fekete", "lilla"],
    ],
  },
  {
    pid: "",
    name: " simply dummy text of the printing and typesetting",
    code: "fl942",
    url: "https://images.unsplash.com/photo-1524995997946-a1c2e315a42f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    length: 1,
    fakeState: [
      ["adidas", "nike"],
      ["puma", "calvin cline"],
      ["armani", "versace"],
      ["hugo boss", "fila"],
    ],
  },
  {
    pid: "33333",
    name: "comes from sections 1.10.32 and 1.10.33 ofde Finibus Bonorum",
    code: "fx312",
    url: "https://images.unsplash.com/photo-1546410531-bb4caa6b424d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80",
    fakeState: [
      ["yahoo", "google"],
      ["msn", "freemail"],
      ["citromail", "aon"],
      ["hollo", "rigo"],
    ],

    length: 23,
  },
  {
    pid: "23233323",
    name: "making it look like readable English.",
    code: "fg242",
    url: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1009&q=80",
    fakeState: [
      ["facebook", "twitter"],
      ["snapchat", "github"],
      ["gmail", "docker"],
      ["kubernetes", "yy"],
    ],

    length: 1000,
  },
  {
    pid: "23232",
    name: " simply dummy text of the printing and typesetting",
    code: "fo142",
    url: "https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    length: 130,
    fakeState: [
      ["react", "next"],
      ["angular", "vue"],
      ["svelte", "amber"],
      ["electric", "jquery"],
    ],
  },
  {
    pid: "2222",
    name: "Quisque interdum, ante at finibus suscipit, magna odio",
    code: "fl362",
    url: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1009&q=80",
    length: 18,
    fakeState: [
      ["chelsea", "Mu"],
      ["aston villa", "west ham"],
      ["man city", "liverpool"],
      ["brigton", "tottanham"],
    ],
  },
  {
    pid: "1123",
    name: "of the printing and typesetting",
    code: "fb742",
    url: "https://images.unsplash.com/photo-1509062522246-3755977927d7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1104&q=80",
    length: 100,
    fakeState: [
      ["fradi", "honved"],
      ["mtk", "vasas"],
      ["videoton", "paks"],
      ["pmfc", "soroksar"],
    ],
  },
];
