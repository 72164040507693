import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { paks } from "../../mockups/pakli-mockup";
import { deletePakliFromDB } from "../../utils/utilities/utility.functions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsModal } from "../../utils/updatemodel";
import { setprogress } from "../../utils/reducer";
import { addheaderLine } from "../../utils/ui-reducer";

const PakliAccordion = ({ items }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCoppied] = useState(false);
  const deletPakk = async () => {
    const del = await deletePakliFromDB(items.pid, dispatch);
  };
  const createSub = (str) => {
    let stri = "";
    if (str.length > 30) {
      stri = str.substring(0, 27) + "...";
    } else {
      stri = str;
    }
    return stri;
  };
  const copyState = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      return setCoppied(true);
    } catch (err) {
      console.log(err);
    }
  };

  const findRandomImage = () => {
    const random = Math.floor(Math.random() * paks.length);
    return paks[random].url;
  };
  const setEndNavigate = () => {
    dispatch(setIsModal(true));
    dispatch(setprogress("check"));
    dispatch(setprogress("upload"));
    dispatch(
      addheaderLine({
        values: {
          lineOne: "active",
          lineTwo: "",
        },
      })
    );
    navigate(`/paklik/${items.pid}`);
  };
  return (
    <div className="pak-accor-top use-bootstrap">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1" className="aco-top">
          <Accordion.Header className="acc-ho-header">
            <div className="pak-accor-inner">
              <div className="my-pakk-img-first"></div>
              <img src={items.imageUrl ? items.imageUrl : findRandomImage()} />
              <div className="my-pakk-img-sec"></div>
            </div>
            <div className="pak-accor-center">
              <span>{createSub(items.stack_name)}</span>
              <span>
                <p style={{ color: "#989898" }}>
                  {items && items.words_size} szó
                </p>
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="acco-body">
              <div className="my-pakk-acco-action">
                <div className="my-pakk-acco-code">
                  <p
                    style={{
                      color: "#989898",
                      fontSize: "16px",
                      marginLeft: "15px",
                    }}
                  >
                    Pakli kód:
                  </p>
                  <div className="my-pakk-acco-code-temp">
                    <span>{items && items.pid}</span>
                  </div>
                </div>
                <div
                  className={
                    !copied ? "my-pakk-acco-copy" : "my-pakk-acco-copy dis"
                  }
                >
                  <button
                    disabled={!copied ? false : true}
                    onClick={() => copyState(items && items.pid)}
                  >
                    {!copied ? "Kód másolása" : "Kód másolva"}
                  </button>
                </div>
                <div className="my-pakk-acco-edit">
                  <button onClick={setEndNavigate}>Szerkesztés</button>
                </div>
                <div className="my-pakk-acco-delete">
                  <button onClick={deletPakk}>Pakli törlése</button>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default PakliAccordion;
