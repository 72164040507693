import React, { useReducer, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Google } from "../images/svg/google.svg";
import { ReactComponent as FB } from "../images/svg/facebook.svg";
import { ReactComponent as Logo } from "../images/doc-images/Tongielogo(Hello).svg";
import validate from "validate.js";
import { constraints } from "../utils/validation/signup";
import { useNavigate } from "react-router-dom";
import {
  initialState,
  errorInitial,
  reducer,
  errorReducer,
} from "../utils/reducer-functions";
import InputMask from "react-input-mask";
import { createUser } from "../utils/create-firebase-user";
import { googleSignIn } from "../utils/google-sign-in";
import { setUser } from "../utils/user.reducer";
import { facebookSignIn } from "../utils/facebook.signin";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
const Signup = () => {
  const capChaRef = useRef();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const setDispatch = useDispatch();
  const googleLogin = () => {
    errorDispatch({ type: "reset" });
    return googleSignIn(errorDispatch, setDispatch, setUser, navigate);
  };
  const faceLogin = () => {
    errorDispatch({ type: "reset" });
    return facebookSignIn(errorDispatch, setDispatch, setUser, navigate);
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errorState, errorDispatch] = useReducer(errorReducer, errorInitial);
  const formSubmit = async (e) => {
    e.preventDefault();
    errorDispatch({ type: "reset" });

    if (state.user.bd === null || state.user.bd.length === 0) {
      return errorDispatch({
        type: "bd",
        payload: "Elfelejtettél születési dátumot hozzáadni",
      });
    }
    const birthday = parseInt(state.user.bd.split("/").join(""));
    const yearAndMonth = state.user.bd.split("/");
    const value = await validate(
      {
        name: state.user.name,
        email: state.user.email,
        password: state.user.password,
        bd: birthday ? birthday : null,
        year: parseInt(yearAndMonth[0]),
        month:
          yearAndMonth[1].charAt(0) === "0"
            ? parseInt(yearAndMonth[1].charAt(1))
            : parseInt(yearAndMonth[1]),
        day:
          yearAndMonth[2].charAt(0) === "0"
            ? parseInt(yearAndMonth[2].charAt(1))
            : parseInt(yearAndMonth[2]),
      },
      constraints
    );

    if (value) {
      return Object.entries(value).map((i) =>
        errorDispatch({
          type: i[0],
          payload: i[1].join().replace(" ", ",").split(",").slice(1).join(),
        })
      );
    }
    if (state.user.password !== state.user.repassword) {
      return errorDispatch({
        type: "repassword",
        payload: "A két jelszó nem egyezik!!",
      });
    }
    const email = state.user.email;
    const password = state.user.password;
    const name = state.user.name.trim();
    const birt = state.user.bd.replaceAll("/", "-").trim();

    const create = await createUser(
      email,
      password,
      birt,
      name,
      errorDispatch,
      capChaRef,
      errorState
    );
  };
  useEffect(() => {
    let controller = new AbortController();
    if (user) {
      navigate("/uploadfile");
    }
    return () => controller.abort();
  }, [user]);
  const checkError = () => {
    const values = Object.values(errorState.errors);
    const element = values.find((el) => el !== null);
    if (element) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="cover-signup">
      <div className="singup-grid">
        <div className="signup-grid-first"></div>
        <div className="signup-grid-sec">
          <div className="signup-form-container">
            <div className="signup-header">
              <h2>Regisztráció</h2>
            </div>
            <form className="signup-form" onSubmit={formSubmit}>
              <div
                className={
                  errorState.errors.name
                    ? "signup-input-holder error"
                    : "signup-input-holder"
                }
              >
                <label htmlFor="name">Név</label>
                <input
                  type="text"
                  id="name"
                  required
                  placeholder="Minta Péter"
                  value={state.user.name}
                  onChange={(e) =>
                    dispatch({ type: "name", payload: e.target.value })
                  }
                />
                {errorState.errors.name && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.name}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
              </div>
              <div
                className={
                  errorState.errors.email
                    ? "signup-input-holder error"
                    : "signup-input-holder"
                }
              >
                <label htmlFor="email">E-mail cím</label>
                <input
                  type="email"
                  id="email"
                  required
                  value={state.user.email}
                  onChange={(e) =>
                    dispatch({ type: "email", payload: e.target.value })
                  }
                />
                {errorState.errors.email && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.email}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
              </div>
              <div
                className={
                  errorState.errors.year ||
                  errorState.errors.bd ||
                  errorState.errors.month ||
                  errorState.errors.day
                    ? "signup-input-holder error"
                    : "signup-input-holder"
                }
              >
                <label htmlFor="bd">Születési év/ hónap/ nap</label>
                <InputMask
                  mask="9999/99/99"
                  maskChar="_"
                  placeholder="Minta: 2017/12/11"
                  value={state.user.bd}
                  onChange={(e) =>
                    dispatch({ type: "bd", payload: e.target.value })
                  }
                />
                {errorState.errors.bd && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.bd}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
                {errorState.errors.year && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.year}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
                {errorState.errors.month && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.month}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
                {errorState.errors.day && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.day}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
              </div>
              <div
                className={
                  errorState.errors.password
                    ? "signup-input-holder error"
                    : "signup-input-holder"
                }
              >
                <label htmlFor="password">Jelszó</label>
                <input
                  type="password"
                  id="password"
                  required
                  value={state.user.password}
                  onChange={(e) =>
                    dispatch({ type: "password", payload: e.target.value })
                  }
                />
                {errorState.errors.password && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.password}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
              </div>
              <div
                className={
                  errorState.errors.repassword
                    ? "signup-input-holder error"
                    : "signup-input-holder"
                }
              >
                <label htmlFor="password-rep">Jelszó mégegyszer</label>
                <input
                  type="password"
                  id="password-rep"
                  value={state.user.repassword}
                  onChange={(e) =>
                    dispatch({ type: "repassword", payload: e.target.value })
                  }
                />
                {errorState.errors.repassword && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.repassword}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
              </div>
              <div className="signup-button-comp">
                <button type="submit">Regisztráció</button>
              </div>
            </form>
            <div className="signup-social-holder">
              <button
                type="button"
                className="sn-google"
                onClick={() => googleLogin()}
              >
                <Google />
              </button>
              <button
                type="button"
                className="sn-face"
                onClick={() => faceLogin()}
              >
                <FB />
              </button>
            </div>
            <div
              className={
                checkError() == false
                  ? "strt-login-comp"
                  : "strt-login-comp error"
              }
            >
              <p>Van már fiókja?</p>
              <Link to="/">Bejelentkezés</Link>
            </div>
            <div>
              {errorState.errors.regError && (
                <Fragment>
                  <p className="error-text" style={{ color: "red" }}>
                    {errorState.errors.regError}
                  </p>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="signup-grid-third">
          <Logo />
        </div>
        <ReCAPTCHA
          ref={capChaRef}
          badge="bottomleft"
          onError={() => alert("Error")}
          size="invisible"
          sitekey={
            process.env.NODE_ENV === "dev"
              ? process.env.REACT_APP_RECAPCHA_TEST_SITE_KEY
              : process.env.REACT_APP_RECAPCHA_SITE_KEY
          }
          onErrored={() =>
            dispatch({
              type: "regError",
              payload: "Error validating Google reCAPTCHA",
            })
          }
          onExpired={() =>
            dispatch({
              type: "regError",
              payload: "Your Google reCAPTCHA expired refresh the page",
            })
          }
          onChange={() => console.log("done")}
        />
      </div>
    </div>
  );
};

export default Signup;
