const ModalImage = (props) => {
  const { selectedId, url } = props;
  return (
    <div
      className={
        selectedId === url
          ? "image-modal-grid-image active"
          : "image-modal-grid-image "
      }
    >
      <img src={url} />
    </div>
  );
};
export default ModalImage;
