import { useCallback, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import {
  setprogress,
  addExel,
  toggleModal,
  addmodalvalues,
  resetModalvalues,
} from "../utils/reducer";
import { setBorder, resetBorder } from "../utils/ui-reducer";
import { addheaderLine } from "../utils/ui-reducer";
import { useSelector } from "react-redux";
import ReactModal from "../components/UI/Modal";
import { v4 as uuidv4 } from "uuid";

const Dropzone = (props) => {
  const navigate = useNavigate();
  const [show, setSwow] = useState(false);
  const state = useSelector((state) => state.model.exelUpload);
  const dispatch = useDispatch();
  const type = "upload";
  const onDragEnter = (event) => {
    const accepted = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    const fileType = event.dataTransfer.items[0].type;
    if (!accepted.includes(fileType)) {
      dispatch(setBorder("danger"));
      return;
    }
    return dispatch(setBorder("succes"));
  };

  const onDragLeave = useCallback(() => {
    dispatch(resetBorder());
  }, []);
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length > 0) {
      // const rejectionArray = fileRejections.map(({ file, errors }) =>
      //   errors.map((e) => e.message)
      // );
      return new Promise((resolve, reject) => {
        resolve(
          dispatch(
            addmodalvalues({
              message: [
                "Kérlek ellenőrizd, hogy a feltöltött fájl *.xlsx *.xls *.csv formátum egyike.",
              ],
              title: "Valami hiba történt!",
              buttonFirst: "",
              buttonSec: "Rendben",
              isDouble: false,
            })
          )
        );
      }).then(() => {
        dispatch(toggleModal(true));
        setSwow(true);
        dispatch(resetBorder());
      });
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading failed");
    reader.onload = () => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const index = data.findIndex((el) => el.length !== 2);
      if (index > -1) {
        dispatch(
          addmodalvalues({
            message: [
              `Kérlek ellenőrizd, feltöltött fájl formátumát ,hiba az ${
                index + 1
              }.  sorban`,
            ],
            title: "Valami hiba történt!",
            buttonFirst: "",
            buttonSec: "Rendben",
            isDouble: false,
          })
        );
        dispatch(toggleModal(true));
        setSwow(true);
        dispatch(resetBorder());
        return;
      }
      const filtered = data.map((el) => ({
        lang_one: el[0],
        lang_two: el[1],
        did: uuidv4(),
      }));
      dispatch(addExel(filtered));
      dispatch(setprogress(type));
      dispatch(
        addheaderLine({
          values: {
            lineOne: "active",
            lineTwo: "",
          },
        })
      );
      navigate("/uploaded");
    };

    acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
  }, []);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      noKeyboard: true,
      multiple: false,
      accept:
        "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
      onDrop,
      onDragEnter,
      onDragLeave,
    });

  const modalClose = () => {
    setTimeout(() => {
      setSwow(false);
    }, 500);
    dispatch(toggleModal(false));
    dispatch(resetModalvalues());
  };

  return (
    <Fragment>
      <ReactModal show={show} action={modalClose} handleClose={modalClose} />
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {props.children}
      </div>
    </Fragment>
  );
};
export default Dropzone;
