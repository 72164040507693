import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./utility-comps/nav";
import { approutes, common } from "./utils/routes";
import NoMatch from "./pages/page404";
import Footer from "./utility-comps/footer";
import { AuthProvider } from "./hooks/userContext";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { useUserInn } from "./hooks/uservalidate";
import Login from "./pages/login";
import UploadFile from "./components/upload/uploadfile";
function App() {
  const { user } = useSelector((state) => state.user);
  useUserInn(user);
  const routesMap = user
    ? approutes.filter((route) => route.protected === true)
    : approutes.filter((route) => route.protected === false);
  return (
    <div className="application">
      <BrowserRouter>
        <AuthProvider>
          <Navigation />

          <Routes>
            {routesMap.map((r) => (
              <Route
                path={r.path}
                element={r.component}
                exact={true}
                key={r.id}
              />
            ))}
            {common.map((route) => (
              <Route
                path={route.path}
                element={route.component}
                exact={true}
                key={route.id}
              />
            ))}
            <Route
              path="/"
              exact={true}
              element={!user ? <Login /> : <UploadFile />}
            />

            <Route path="*" element={<NoMatch />} />
          </Routes>
          <Footer />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
