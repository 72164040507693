export const initialState = {
  user: {
    name: "",
    email: "",
    password: "",
    repassword: "",
    bd: "",
  },
};

export const errorInitial = {
  errors: {
    name: null,
    email: null,
    password: null,
    repassword: null,
    regError: null,
    bd: null,
    day: null,
    year: null,
    month: null,
  },
};
export const errorReducer = (state, { type, payload }) => {
  switch (type) {
    case "name":
      return {
        ...state,
        errors: {
          ...state.errors,
          name: payload,
        },
      };
    case "bd":
      return {
        ...state,
        errors: {
          ...state.errors,
          bd: payload,
        },
      };
    case "email":
      return {
        ...state,
        errors: {
          ...state.errors,
          email: payload,
        },
      };
    case "password":
      return {
        ...state,
        errors: {
          ...state.errors,
          password: payload,
        },
      };
    case "repassword":
      return {
        ...state,
        errors: {
          ...state.errors,
          repassword: payload,
        },
      };
    case "regError": {
      return {
        ...state,
        errors: {
          ...state.errors,
          regError: payload,
        },
      };
    }
    case "year": {
      return {
        ...state,
        errors: {
          ...state.errors,
          year: payload,
        },
      };
    }
    case "month": {
      return {
        ...state,
        errors: {
          ...state.errors,
          month: payload,
        },
      };
    }
    case "day": {
      return {
        ...state,
        errors: {
          ...state.errors,
          day: payload,
        },
      };
    }
    case "reset":
      return errorInitial;

    default:
      throw new Error();
  }
};
export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "name":
      return {
        ...state,
        user: {
          ...state.user,
          name: payload,
        },
      };
    case "email":
      return {
        ...state,
        user: {
          ...state.user,
          email: payload,
        },
      };
    case "password":
      return {
        ...state,
        user: {
          ...state.user,
          password: payload,
        },
      };
    case "repassword":
      return {
        ...state,
        user: {
          ...state.user,
          repassword: payload,
        },
      };
    case "bd":
      return {
        ...state,
        user: {
          ...state.user,
          bd: payload,
        },
      };
    case "reset":
      return initialState;

    default:
      throw new Error();
  }
};
