import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: false,
};
export const MainUserModel = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});
export const { setUser } = MainUserModel.actions;

export default MainUserModel.reducer;
