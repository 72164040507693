import Check from "../utility-icons/check";
import Upload from "../utility-icons/upload";
import Share from "../utility-icons/share";
import Notes from "../utility-icons/notes";
import Photo from "../utility-icons/photo";

const UtilityIcon = (props) => {
  const { stage } = props;

  const iconStages = {
    upload: <Upload />,
    done: <Check />,
    share: <Share />,
    check: <Notes />,
    photo: <Photo width="24pt" fill="#111" />,
  };
  return <div>{iconStages[stage]}</div>;
};

export default UtilityIcon;
