import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../images/doc-images/tongielogo.svg";
import Info from "../utility/utility-icons/info";

import { useDispatch } from "react-redux";
import HeaderBalls from "../../shared/header-balls";
import { useNavigate, useParams } from "react-router-dom";
import { resetPartial } from "../../utils/utilities/utility.functions";
import { resetAllUpload } from "../../utils/updatemodel";

const FinisHedUpload = () => {
  const [pakli, setPakli] = useState();
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();
  const [codied, setCopied] = useState(false);
  const resetAndNavigate = () => {
    resetPartial(dispatch);
    dispatch(resetAllUpload());
    navigate("/uploadfile");
  };
  const copyState = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      return setCopied(true);
    } catch (err) {
      console.log(err);
    }
  };
  const baseUrl =
    process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      try {
        const response = await fetch(`${baseUrl}words/${params.pid}`, {
          method: "GET",
          credentials: "include",
        });
        if (response.status === 200) {
          const resp = await response.json();
          setPakli(resp);
        }
      } catch (e) {
        console.log(e);
      }
      return controller.abort();
    })();
  }, [params.pid]);
  return (
    <div style={{ height: "100vh" }}>
      <div className="header-balls active">
        <HeaderBalls />
      </div>
      <div className="fin-holder">
        <div className="fin-grid">
          <div className="inner-holders">
            <div className="container-first">
              <div></div>
              <div className="container-image-gridd">
                <div className="img-first"></div>
                <img
                  src={
                    pakli
                      ? pakli.image_url
                      : "https://images.unsplash.com/photo-1509062522246-3755977927d7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1104&q=80"
                  }
                  alt="pakliimage"
                />
                <div className="img-sec"></div>
              </div>
              <div className="first-text">
                <h4>{pakli ? pakli.stack_name : "Pakli név"}</h4>
              </div>
            </div>
            <div></div>
          </div>
          <div className="inner-holders">
            <div className="container-second">
              <div></div>
              <div className="upper-button-holder">
                <p>Paklid kódja</p>
                <div className="codtext">
                  <h3>{pakli ? params.pid : ""}</h3>
                </div>
                <div className="codtex-holder">
                  <div className="button-hold">
                    <button
                      className="button-passive"
                      onClick={() => copyState(params.pid)}
                    >
                      {!codied ? "Kód másolása" : "Kód másolva"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-holders">
            <div className="container-third">
              <div className="tool">
                <div className="sec-tooltip">
                  <div className="tooltip-top">
                    <div className="tooltiptext">Táblázat feltöltése</div>
                    <Info color="#A7A7A7" />
                  </div>
                </div>
              </div>
              <div className="logo-hold">
                <Logo />
              </div>
              <div className="button-hold">
                <div className="int">
                  <button
                    className="button-active"
                    onClick={() => resetAndNavigate()}
                  >
                    Új feltöltés
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FinisHedUpload;
