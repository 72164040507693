export const constraints = {
  name: {
    presence: true,
    format: {
      pattern: "[^<>{}()&*%#@><.?/|]+",
      message: "Helytelen karakter használat",
    },
    length: { minimum: 2, message: "Minimum 2 karakter" },
  },
  email: {
    presence: true,
    email: true,
    format: {
      pattern: "[^<>{}()]+",
      message: "Helytelen karakter használat",
    },
  },
  bd: {
    presence: {
      allowEmpty: false,
      message: "Légyszives tölts ki a születési év mezőt!",
    },
    numericality: {
      onlyInteger: true,
      message: "Helytelen születési dátum",
    },
  },
  year: {
    numericality: {
      onlyInteger: true,
      message: "Helytelen születési év",
      greaterThan: 1920,
      lessThanOrEqualTo: 2010,
    },
  },
  month: {
    numericality: {
      onlyInteger: true,
      message: "Helytelen születési hónap",
      greaterThan: 0,
      lessThanOrEqualTo: 12,
    },
  },
  day: {
    numericality: {
      onlyInteger: true,
      message: "Helytelen születési nap",
      greaterThan: 0,
      lessThanOrEqualTo: 31,
    },
  },
  password: {
    format: {
      pattern: "[^<>{}()]+",
      message: "Helytelen karakter használat",
    },
  },
};
