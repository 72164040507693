import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleModal, addmodalvalues, setprogress } from "../../utils/reducer";
import { addheaderLine } from "../../utils/ui-reducer";
import { useSelector } from "react-redux";
import { lang, nameImage, name } from "../../utils/utilities/error.values";
import { resetPartial } from "../../utils/utilities/utility.functions";
import { resetAllUpload } from "../../utils/updatemodel";
const TableFooter = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSwow, updateRows } = props;
  const { isModal } = useSelector((state) => state.update);
  const rows = !isModal
    ? useSelector((state) => state.model.rowCount)
    : useSelector((state) => state.update.rows);
  const statusCheck = useSelector((state) => state.model.procceses);
  const state = useSelector((state) => state.model.exelUpload);
  const pakli = !isModal
    ? useSelector((state) => state.pakli)
    : useSelector((state) => state.update.pakliUpload);
  const modalState = useSelector((state) => state.model.displayModal);
  const imageModal = useSelector((state) => state.model.imagesModal);
  const { langOne, langTwo } = !isModal
    ? useSelector((state) => state.model)
    : useSelector((state) => state.update);

  const { pakliName, pakliImage, pakliCode } = pakli;
  const comletedRows = useCallback(() => {
    const t = rows.filter((e) => e.lang_one !== "" && e.lang_two != "");
    return t;
  }, [rows]);
  const trows = comletedRows();

  const setDone = async () => {
    if (!pakliImage) {
      return getError(nameImage);
    }
    if (!pakliName) {
      return getError(name);
    }
    if (!langOne || !langTwo) {
      return getError(lang);
    }
    dispatch(setprogress("share"));
    dispatch(setprogress("image"));

    dispatch(setprogress("check"));
    dispatch(
      addheaderLine({
        values: {
          lineOne: "completed",
          lineTwo: "active",
        },
      })
    );
    const rs = comletedRows();
    const exelArray = Array.isArray(state)
      ? state.filter((el) => el.lang_one !== "" && el.lang_two !== "")
      : null;
    const arrWords = state ? [...state, ...rs] : rs;

    dispatch(
      addheaderLine({ values: { lineOne: "completed", lineTwo: "completed" } })
    );

    const baseUrl =
      process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
    const url = pakliCode
      ? `${baseUrl}save_stack/${pakliCode}`
      : `${baseUrl}save_stack`;
    const words = arrWords.map((el) => ({
      lang_1: el.lang_one,
      lang_2: el.lang_two,
      id: el.id ? el.id : "",
    }));
    const respObj = {
      stack_name: pakliName,
      lang_1: langOne,
      lang_2: langTwo,
      image_url: pakliImage,
      words: words,
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(respObj),
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200 || response.status === 201) {
        const resp = await response.json();
        navigate(`/done/${resp.pid && resp.pid}`);
        setTimeout(() => {
          dispatch(setprogress("completed"));
        }, 500);
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const goBack = async () => {
    resetPartial(dispatch);
    dispatch(resetAllUpload());
    setSwow(false);
    navigate("/uploadfile");
  };

  const deleteAll = () => {
    dispatch(
      addmodalvalues({
        isAction: true,
        message: [
          "Amennyiben visszalépsz, az összes eddig hozzáadott szavad törlésre kerül!",
          "Biztos vagy benne, hogy visszalépsz a feltöltéshez?",
        ],
        title: "Visszalépés a feltöltéshez",
        buttonFirst: " Mégsem",
        buttonSec: " Visszalépés",
        isDouble: true,
      })
    );
    dispatch(toggleModal(true));
    setSwow(true);
  };

  const getError = (error) => {
    dispatch(addmodalvalues(error));
    dispatch(toggleModal(true));
    setSwow(true);
  };
  return (
    <div className={modalState || imageModal ? "hidden-vis" : "file-footer"}>
      <button
        className="footer-button"
        onClick={state || trows.length > 0 ? deleteAll : goBack}
      >
        Vissza
      </button>
      <button
        onClick={setDone}
        className={
          statusCheck.without && trows.length === 0
            ? "footer-button disabled"
            : "footer-button active"
        }
      >
        következő
      </button>
    </div>
  );
};
export default React.memo(TableFooter);
