import React, { useState } from "react";
import { ReactComponent as Logo } from "../images/doc-images/Tongielogo(Hello).svg";
import validate from "validate.js";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";

const Recovery = () => {
  const auth = getAuth();
  const [error, setError] = useState("");
  const [thank, setThank] = useState("");
  const [reemail, setReemail] = useState();
  console.log(error);
  const sendEmail = async (e) => {
    e.preventDefault();
    setError(null);
    setThank(null);
    const value = validate.single(reemail, { presence: true, email: true });
    if (value) {
      return setError("Nem megfelelö karakter használat");
    }
    try {
      await sendPasswordResetEmail(auth, reemail);
      setThank("Az új jelszóhoz való linket elküldtük a megadott email cimre.");
    } catch (err) {
      const errorMsg =
        err.code === "auth/user-not-found"
          ? "Ezzel az email cimmel nincs regisztrált felhasználó"
          : "Hiba az azonosítás során";
      setError(errorMsg);
    }
  };
  return (
    <div className="cover-signup">
      <div className="singup-grid">
        <div className="signup-grid-first">
          <h1>Elfelejtett jelszó</h1>
        </div>
        <div className="signup-grid-sec">
          <div
            className="signup-form-container"
            style={{ width: "500px", height: "350px" }}
          >
            <div className="signup-header">
              <h2>Elfelejtett jelszó</h2>
            </div>
            <form className="signup-form" onSubmit={sendEmail}>
              <div
                className={
                  error ? "signup-input-holder error" : "signup-input-holder"
                }
              >
                <label htmlFor="em">Regisztrált Email</label>
                <input
                  type="email"
                  id="em"
                  required
                  placeholder="Minta Péter@tongie.com"
                  value={reemail}
                  onChange={(e) => setReemail(e.target.value)}
                />
              </div>
              <div className="signup-button-comp">
                <button type="submit">Küldés</button>
              </div>
            </form>
            <div className={error ? "recoveryError" : "recoveryError active"}>
              <p>{error ? error : thank}</p>
            </div>
          </div>
        </div>
        <div className="signup-grid-third login-forth">
          <Logo />
        </div>
      </div>
    </div>
  );
};

export default Recovery;
