import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const modeRef = useRef(true);
  const auth = getAuth();
  const [users, setUser] = useState();
  useEffect(async () => {
    await onAuthStateChanged(auth, (use) => {
      if (use && modeRef.current) {
        setUser({ uid: use.uid, name: use.displayName, email: use.email });
        return;
      } else {
        if (modeRef.current) {
          setUser(undefined);
          return;
        }
      }
    });
    return () => {
      modeRef.current = false;
    };
  }, []);
  return (
    <AuthContext.Provider value={{ users }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
