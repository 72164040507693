import React, { Fragment, useEffect, useState } from "react";
import PakliRow from "../utility-comps/pakli-row";
import { resetAllUpload, setPakliwords } from "../utils/updatemodel";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactModal from "../components/UI/Modal";
import { toggleModal, resetModalvalues } from "../utils/reducer";
import { setDeleteId } from "../utils/updatemodel";
import { deletePakliFromDB } from "../utils/utilities/utility.functions";

const Paklik = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pakliwords, deleteId, pakliUpload } = useSelector(
    (state) => state.update
  );
  const [show, setSwow] = useState(false);
  const baseUrl =
    process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      try {
        const response = await fetch(`${baseUrl}words`, {
          method: "GET",
          credentials: "include",
        });
        if (response.status === 200) {
          const word = await response.json();
          const words = await word.map((el) => ({
            ...el,
            imageUrl: el.image_url,
          }));
          dispatch(setPakliwords(words));
        }
      } catch (err) {
        console.log(err);
      }
    })();
    return () => controller.abort();
  }, [dispatch]);
  const closeModal = () => {
    setSwow(false);
    dispatch(toggleModal(false));
    dispatch(resetModalvalues());
    dispatch(setDeleteId(null));
  };
  const pakliDelete = async () => {
    const deletePak = await deletePakliFromDB(deleteId && deleteId, dispatch);
    closeModal();
    if (pakliUpload.pakliCode === deleteId) {
      dispatch(resetAllUpload());
    }
  };
  const modalState = useSelector((state) => state.model.displayModal);
  return (
    <Fragment>
      <div style={{ display: "none" }}>
        <ReactModal handleClose={closeModal} show={show} action={pakliDelete} />
      </div>

      <div className={modalState ? "hidden-vis" : ""}>
        <h2 className="lead-pakli">Paklijaim</h2>
        <div className="top-div">
          <div className="my-paks-cover">
            <div className="my-paks-inner">
              <div className="my-pakk-grid">
                {pakliwords &&
                  pakliwords.map((item, index) => (
                    <PakliRow key={index} items={item} setShow={setSwow} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Paklik;
