import React from "react";
import { useSelector } from "react-redux";
import UtilityIcon from "../components/utility/icon/utility-icon";
const HeaderBalls = ({ mode, icon }) => {
  const statusCheck = useSelector((state) => state.model.procceses);
  const headerStyles = useSelector((state) => state.ui.headerLines);
  const state = useSelector((state) => state.model.exelUpload);
  return (
    <div
      className={
        !statusCheck.without ? "row inner-row" : "row inner-row active"
      }
    >
      <div className="icon-top-holder">
        <div
          className={statusCheck.upload ? "header-item active" : "header-item"}
        >
          <UtilityIcon
            stage={!statusCheck.upload ? "upload" : "done"}
            current={!statusCheck.upload ? "active" : "completed"}
          />
        </div>

        <p className={statusCheck.upload ? "iconCompleted" : "iconBasicColor"}>
          Feltöltés
        </p>
      </div>
      <span className={`line ${headerStyles.lineOne}`}></span>
      <span className={statusCheck.without ? "noDisplay" : ""}>
        <div className="icon-top-holder">
          <span className={state ? "header-item active" : "header-item "}>
            <UtilityIcon stage={!statusCheck.check ? "check" : "done"} />
          </span>
          <p className={statusCheck.check ? "iconCompleted" : "iconBasicColor"}>
            Ellenőrzés
          </p>
        </div>
      </span>
      <span className={`line ${headerStyles.lineOne}`}></span>
      <span className={statusCheck.without ? "noDisplay" : ""}>
        <div className="icon-top-holder">
          <span className={state ? "header-item active" : "header-item "}>
            <UtilityIcon stage={!statusCheck.image ? "photo" : "done"} />
          </span>
          <p className={statusCheck.image ? "iconCompleted" : "iconBasicColor"}>
            Borítókép
          </p>
        </div>
      </span>
      <span
        className={
          !statusCheck.without ? `line ${headerStyles.lineTwo}` : "noDisplay"
        }
      ></span>
      <div className="icon-top-holder">
        <span
          className={statusCheck.check ? "header-item active" : "header-item"}
        >
          <span>
            <UtilityIcon stage={!statusCheck.completed ? "share" : "done"} />
          </span>
        </span>
        <p className={!statusCheck.image ? "iconBasicColor" : "iconCompleted"}>
          Megosztás
        </p>
      </div>
    </div>
  );
};
export default HeaderBalls;
