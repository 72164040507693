import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../utils/user.reducer";

export const useUserInn = (user) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let container = new AbortController();
    (async () => {
      const baseUrl =
        process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
      const response = await fetch(`${baseUrl}validate`, {
        method: "GET",
        credentials: "include",
      });
      if (response.status !== 200) {
        return dispatch(setUser(false));
      } else {
        return dispatch(setUser(true));
      }
    })();
    return () => container.abort();
  }, [dispatch, user]);
};
