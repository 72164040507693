import React from "react";
import { footerLinks } from "../utils/routes";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="nav-footer">
      <ul className="nav-footer-holder">
        {footerLinks
          .sort((a, b) => b.id > a.id)
          .map((route) => (
            <li key={route.id} className="footer-link">
              <NavLink to={route.path}>{route.text}</NavLink>
            </li>
          ))}
      </ul>
      <div className="company">
        <p>2021 Tongie. Minden jog fenntartva</p>
      </div>
    </div>
  );
};

export default Footer;
