import React, { Fragment, useEffect } from "react";
import Dropzone from "../../hooks/dropzone";
import Center from "../UI/dropzone-center";
import { useSelector, useDispatch } from "react-redux";
import HeaderBalls from "../../shared/header-balls";
import { useNavigate } from "react-router-dom";
import { resetPartial } from "../../utils/utilities/utility.functions";
const UploadFile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalStatus = useSelector((state) => state.model.imagesModal);
  const statusModal = useSelector((state) => state.model.displayModal);
  const border = useSelector((state) => state.ui.contBorder);
  const { completed } = useSelector((state) => state.model.procceses);
  useEffect(() => {
    let controller = new AbortController();
    if (completed) {
      resetPartial(dispatch);
    }
    return () => controller.abort();
  }, [completed]);
  return (
    <Fragment>
      <div
        className={modalStatus || statusModal ? "onModal active" : "onModal"}
      >
        <div className="header-balls">
          <HeaderBalls />
        </div>
        <section
          className={
            modalStatus || statusModal ? "noDisplay" : "flcontainer tyy"
          }
        >
          <div className={`inner-container ${border}`}>
            <div
              className={
                modalStatus || statusModal
                  ? "upload-container active"
                  : "upload-container"
              }
            >
              <Dropzone>
                <Center />
              </Dropzone>{" "}
              <button
                className="without-upload"
                onClick={() => navigate("/upwithout")}
              >
                Létrehozás táblázat nélkül
              </button>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
      </div>{" "}
    </Fragment>
  );
};

export default UploadFile;
