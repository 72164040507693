import React, { useReducer, Fragment, useEffect, useRef } from "react";
import { ReactComponent as Google } from "../images/svg/google.svg";
import { ReactComponent as FB } from "../images/svg/facebook.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../images/doc-images/Tongielogo(Hello).svg";
import validate from "validate.js";
import { constraints } from "../utils/validation/login";
import { loginUser } from "../utils/login-firebase-user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  initialState,
  errorInitial,
  reducer,
  errorReducer,
} from "../utils/reducer-functions";
import { googleSignIn } from "../utils/google-sign-in";
import { setUser } from "../utils/user.reducer";
import { facebookSignIn } from "../utils/facebook.signin";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errorState, errorDispatch] = useReducer(errorReducer, errorInitial);
  const setDispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const googleLogin = () => {
    errorDispatch({ type: "reset" });
    return googleSignIn(errorDispatch, setDispatch, setUser, navigate);
  };
  const faceLogin = () => {
    errorDispatch({ type: "reset" });
    return facebookSignIn(errorDispatch, setDispatch, setUser, navigate);
  };
  const capChaRef = useRef();
  const formSubmit = async (e) => {
    e.preventDefault();
    capChaRef.current.reset();
    errorDispatch({ type: "reset" });
    const value = await validate(
      {
        email: state.user.email,
        password: state.user.password,
      },
      constraints
    );
    if (value) {
      return Object.entries(value).map((i) =>
        errorDispatch({
          type: i[0],
          payload: i[1].join().replace(" ", ",").split(",").slice(1).join(),
        })
      );
    }
    const email = state.user.email;
    const password = state.user.password;
    const us = await loginUser(
      email,
      password,
      errorDispatch,
      setDispatch,
      setUser,
      navigate,
      capChaRef,
      errorState
    );
  };
  useEffect(() => {
    let controller = new AbortController();
    if (user) {
      navigate("/uploadfile");
    }
    return () => controller.abort();
  }, [user]);

  return (
    <div className="cover-signup">
      <div className="singup-grid">
        <div className="signup-grid-first"></div>
        <div className="signup-grid-sec">
          <div className="signup-form-container login-form-cont">
            <form className="signup-form" onSubmit={formSubmit}>
              <div className="signup-header">
                <h2>Bejelentkezés</h2>
              </div>
              <div
                className={
                  errorState.errors.email
                    ? "signup-input-holder error"
                    : "signup-input-holder"
                }
              >
                <label htmlFor="email">E-mail cím</label>
                <input
                  type="email"
                  id="email"
                  required
                  placeholder="peter@tongie.app"
                  value={state.user.email || ""}
                  onChange={(e) =>
                    dispatch({ type: "email", payload: e.target.value })
                  }
                />
                {errorState.errors.email && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.email}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
              </div>
              <div
                className={
                  errorState.errors.password
                    ? "signup-input-holder error"
                    : "signup-input-holder"
                }
              >
                <label htmlFor="password">Jelszó</label>
                <input
                  type="password"
                  id="password"
                  required
                  value={state.user.password}
                  onChange={(e) =>
                    dispatch({ type: "password", payload: e.target.value })
                  }
                />
                {errorState.errors.password && (
                  <Fragment>
                    <p className="error-text">{errorState.errors.password}</p>
                    <div className="error-sign">!</div>
                  </Fragment>
                )}
              </div>
              <div className="signup-button-comp">
                <button type="submit">Bejelentkezés</button>
              </div>
            </form>
            <div className="signup-social-holder">
              <button
                type="button"
                className="sn-google"
                onClick={() => googleLogin()}
              >
                <Google />
              </button>
              <button
                type="button"
                className="sn-face"
                onClick={() => faceLogin()}
              >
                <FB />
              </button>
            </div>
            <div className="strt-login-comp login-comp">
              <p>Nincs még fiókja?</p>
              <Link to="/signup">Regisztráció</Link>
            </div>
            <div className="forgoten-pass">
              <Link to="/recovery">Elfelejtette a jelszavát?</Link>
            </div>
            {errorState.errors.regError && (
              <Fragment>
                <p className="error-text login-error" style={{ color: "red" }}>
                  {errorState.errors.regError}
                </p>
              </Fragment>
            )}
          </div>
        </div>
        <div className="signup-grid-third login-third">
          <Logo />
        </div>
        <ReCAPTCHA
          ref={capChaRef}
          badge="bottomleft"
          onError={() => alert("Error")}
          size="invisible"
          sitekey={
            process.env.NODE_ENV === "dev"
              ? process.env.REACT_APP_RECAPCHA_TEST_SITE_KEY
              : process.env.REACT_APP_RECAPCHA_SITE_KEY
          }
          onErrored={() =>
            dispatch({
              type: "regError",
              payload: "Error validating Google reCAPTCHA",
            })
          }
          onExpired={() =>
            dispatch({
              type: "regError",
              payload: "Your Google reCAPTCHA expired refresh the page",
            })
          }
          onChange={() => console.log("done")}
        />
      </div>
    </div>
  );
};

export default Login;
