import React, { useState } from "react";
import ImageUpload from "../components/UI/image-upload";
import ReactModal from "../components/UI/Modal";
import { useSelector } from "react-redux";
import HeaderBalls from "../shared/header-balls";
import TableFooter from "../components/UI/tableFooter";
import { useDispatch } from "react-redux";
import { toggleModal, addmodalvalues } from "../utils/reducer";
import { useNavigate } from "react-router-dom";
import { resetPartial } from "../utils/utilities/utility.functions";
const ImageuploadPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isModal } = useSelector((state) => state.update);
  const [show, setSwow] = useState(false);
  const closeModal = () => {
    return new Promise((resolve, reject) => {
      resolve(
        setTimeout(() => {
          setSwow(false);
        }, 500)
      );
    }).then(() => {
      dispatch(toggleModal(false));
    });
  };
  const onDelete = () => {
    return new Promise((resolve, reject) => {
      resolve(
        setTimeout(() => {
          setSwow(true);
        }, 500)
      );
    }).then(() => {
      resetPartial(dispatch);
      dispatch(toggleModal(false));
      navigate("/uploadfile");
    });
  };
  const getError = (error) => {
    dispatch(addmodalvalues(error));
    dispatch(toggleModal(true));
    setSwow(true);
  };

  return (
    <div style={{ height: "100vh", position: "relative" }}>
      <div style={{ display: "none" }}>
        <ReactModal handleClose={closeModal} show={show} action={onDelete} />
      </div>
      <div className="rela">
        <div className="header-balls active">
          <HeaderBalls />
        </div>
        <div className="image-up-container">
          <ImageUpload isModal={isModal} />
        </div>
        <div className="footer-action">
          <TableFooter setSwow={setSwow} isModal={false} />
        </div>
      </div>
    </div>
  );
};

export default ImageuploadPage;
