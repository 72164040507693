import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeRow,
  addNewRow,
  addFirstRow,
  addSecondRow,
} from "../../utils/reducer";
import {
  removeUploadItem,
  modifyOne,
  modifyTwo,
  addNewRowUpdated,
} from "../../utils/updatemodel.js";
import Bin from "../utility/utility-icons/bin";
import { v4 as uuidv4 } from "uuid";

const Holder = (props) => {
  const { row, isModal } = props;
  const [fieldOne, setOne] = useState(null);
  const [fieldTwo, setTwo] = useState(null);
  const [error, setError] = useState(false);
  const modalState = useSelector((state) => state.model.displayModal);
  const { langOne, langTwo } = !isModal
    ? useSelector((state) => state.model)
    : useSelector((state) => state.update);
  const { upload } = useSelector((state) => state.update);
  const dispatch = useDispatch();
  const onDelete = () => {
    !isModal ? dispatch(removeRow(row.did)) : rowDelete();
  };
  const addFirst = useCallback((item, did) => {
    !isModal && setOne(item);
    dispatch(!isModal ? addFirstRow({ item, did }) : modifyOne({ item, did }));
  }, []);

  const addSecond = useCallback((item, did) => {
    !isModal && setTwo(item);
    dispatch(!isModal ? addSecondRow({ item, did }) : modifyTwo({ item, did }));
  }, []);

  const onMouse = useCallback(
    (e) => {
      e.stopPropagation();
      const did = uuidv4();
      if (isModal) {
        dispatch(addNewRowUpdated(did));
      } else {
        if (!fieldOne || !fieldTwo) {
          setError(true);
          return;
        } else {
          dispatch(addNewRow(did));
          setError(false);
        }
      }
    },
    [dispatch, row.lang_one, row.lang_two]
  );
  const baseUrl =
    process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
  const rowDelete = async () => {
    try {
      const response = await fetch(
        `${baseUrl}delete_word?pid=${upload && upload.pid}&id=${
          row.id && row.id
        }`,
        { method: "DELETE", credentials: "include" }
      );
      if (response.status === 200) {
        dispatch(removeUploadItem(row.did));
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <div
        className={
          !error || isModal ? "row-grid w-full" : "row-grid line-error"
        }
      >
        <div className="whitespace-nowrap row-inner">
          <div className="flex items-center">
            <input
              type="text"
              value={row.lang_one || ""}
              onChange={(e) => addFirst(e.target.value, row.did)}
              onPointerLeave={(e) => onMouse(e)}
              placeholder={langOne ? langOne : "ENG"}
              className="m-1 rounded w-full h-10 py-2 px-3 focus:outline-none focus:shadow-outline input-inner "
            />
          </div>
        </div>
        <div className="whitespace-nowrap row-inner">
          <div className="flex items-center">
            <input
              type="text"
              value={row.lang_two || ""}
              onChange={(e) => addSecond(e.target.value, row.did)}
              placeholder={langTwo ? langTwo : "HUN"}
              className="m-1 rounded w-full h-10 py-2 px-3 focus:outline-none focus:shadow-outline input-inner"
              onPointerLeave={(e) => onMouse(e)}
            />
          </div>
        </div>
        <div className="whitespace-nowrap flex items-center ring-0 bin-collumn">
          <button
            type="button"
            onClick={() => onDelete()}
            className="outline-none ring-0"
            disabled={modalState}
          >
            <Bin color="#A7A7A7" width="20pt" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Holder);
