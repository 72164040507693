import React from "react";
import Holder from "./holder";

const AddedRows = ({ rows, isModal }) => {
  return (
    <div>
      {rows.map((items, indx) => (
        <div key={indx}>
          <Holder index={indx} row={items} isModal={isModal} />
        </div>
      ))}
    </div>
  );
};

export default AddedRows;
