import React, { useEffect } from "react";
import logimageo from "../images/doc-images/viszontlatasra.jpeg";
import { ReactComponent as Logo } from "../images/doc-images/dashlogo.svg";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../utils/user.reducer";
import { resetAll } from "../utils/utilities/utility.functions";
import { resetAllUpload } from "../utils/updatemodel";
const SignOut = () => {
  const dispatch = useDispatch();
  const auth = getAuth();
  const navigate = useNavigate();
  const baseUrl =
    process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
  useEffect(() => {
    let controller = new AbortController();
    (() => {
      setTimeout(async () => {
        const response = await fetch(`${baseUrl}logout`, {
          method: "POST",
          credentials: "include",
        });
        if (response.status === 200) {
          dispatch(setUser(false));
          await signOut(auth);
          resetAll(dispatch);
          dispatch(resetAllUpload());
          navigate("/");
        }
      }, 500);
    })();
    return controller.abort();
  }, [dispatch]);
  return (
    <div className="logout">
      <div className="logout-img">
        <img src={logimageo} />
      </div>
      <Logo />
    </div>
  );
};

export default SignOut;
