import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const NoMatch = () => {
  const { user } = useSelector((state) => state.model);
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">
                  {user
                    ? "Az oldal nem található"
                    : "Jelentkezz be az oldal használatához"}
                </h3>

                <p>
                  {user
                    ? "the page you are looking for not avaible!"
                    : "Please, sign in to continue!"}
                </p>

                <Link to={user ? "/home" : "/"} className="link_404">
                  {user ? "Főoldal" : "Bejelentkezés"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NoMatch;
