import React from "react";
import { NavLink, useLocation } from "react-router-dom";
const Menulink = ({ path, text }) => {
  const { pathname } = useLocation();
  const route = pathname === "home" && path === "uploadfile" ? false : true;

  return (
    <li
      className={
        path !== pathname && route ? "link-element" : "link-element link-active"
      }
    >
      <NavLink to={path}>{text}</NavLink>
    </li>
  );
};

export default Menulink;
