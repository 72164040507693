import React, { useState } from "react";
import Bin from "../components/utility/utility-icons/bin";
import PakliAccordion from "../components/UI/pakli-accordion";
import { paks } from "../mockups/pakli-mockup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDeleteId, setIsModal } from "../utils/updatemodel";
import { addmodalvalues, toggleModal, setprogress } from "../utils/reducer";
import { deletePakli } from "../utils/utilities/error.values";
import { addheaderLine } from "../utils/ui-reducer";

const PakliRow = ({ items, setShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCoppied] = useState(false);
  const createSub = (str) => {
    let stri = "";
    if (str.length > 40) {
      stri = str.substring(0, 37) + "...";
    } else {
      stri = str;
    }
    return stri;
  };
  const copyState = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      return setCoppied(true);
    } catch (err) {
      console.log(err);
    }
  };
  const deleteItems = async (e) => {
    e.stopPropagation();
    dispatch(setDeleteId(items.pid));
    dispatch(addmodalvalues(deletePakli));
    dispatch(toggleModal(true));
    setShow(true);
  };
  const findRandomImage = () => {
    const random = Math.floor(Math.random() * paks.length);
    return paks[random].url;
  };
  const setEndNavigate = () => {
    dispatch(setIsModal(true));
    dispatch(setprogress("check"));
    dispatch(setprogress("upload"));
    dispatch(
      addheaderLine({
        values: {
          lineOne: "active",
          lineTwo: "",
        },
      })
    );
    navigate(`/paklik/${items.pid}`);
  };
  return (
    <div className="my-pakk-row">
      <div className="my-pakk-cont">
        <div className="my-pakk-inner">
          <div className="my-pakk-img-holder">
            <div className="my-pakk-img-first"></div>
            <img src={items.image_url ? items.image_url : findRandomImage()} />
            <div className="my-pakk-img-sec"></div>
          </div>
          <div className="my-pakk-center">
            <div className="my-pakk-name">
              <p className="my-pakk-title">{createSub(items.stack_name)}</p>
              <p className="my-pakk-length">{items && items.words_size} szó</p>
            </div>
            <div className="my-pakk-action">
              <div className="my-pakk-code">
                <p style={{ width: "50%", color: "#989898", fontSize: "18px" }}>
                  Pakli kód:
                </p>
                <div className="my-pakk-code-temp">
                  <span>{items && items.pid}</span>
                </div>
              </div>
              <div className={!copied ? "my-pakk-copy" : "my-pakk-copy dis"}>
                <button
                  disabled={!copied ? false : true}
                  onClick={() => copyState(items.pid)}
                >
                  {!copied ? "Kód másolása" : "Kód másolva"}
                </button>
              </div>
              <div className="my-pakk-edit">
                <button onClick={setEndNavigate}>Szerkesztés</button>
              </div>
            </div>
          </div>
          <div className="my-pakk-bin">
            <span onClick={(e) => deleteItems(e)}>
              <Bin width="25px" color="#871111" />
            </span>
          </div>
        </div>
      </div>
      <div className="my-pakk-accord">
        <PakliAccordion items={items} />
      </div>
    </div>
  );
};

export default PakliRow;
