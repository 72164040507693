import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import ModalImage from "./modal-image";
import Close from "../utility/utility-icons/closeButton";
import { addImageId } from "../../utils/pakli-reducer";
import { useDispatch } from "react-redux";
import { changeUpImage } from "../../utils/updatemodel";
import { useSelector } from "react-redux";
const ImageModal = (props) => {
  const dispatch = useDispatch();
  const { handleClose, show, selectedId, setid, isModal } = props;
  const { firabaseImages } = useSelector((state) => state.model);
  const cancelUpload = useCallback(() => {
    handleClose();
  }, [handleClose]);
  const saveimage = useCallback(() => {
    if (!selectedId) return;
    dispatch(addImageId(selectedId));
    setid(null);
    handleClose();
  }, [selectedId, handleClose, dispatch]);
  const addChangeImageUrl = useCallback(() => {
    if (!selectedId) return;
    setid(null);

    dispatch(changeUpImage(selectedId));
    handleClose();
  }, [selectedId, dispatch]);
  return (
    <div className="image-modal" onClick={(e) => e.stopPropagation()}>
      <Modal
        show={show}
        onHide={() => handleClose()}
        className="image-modal-container"
      >
        <Modal.Header closeButton className="image-modal-header">
          <p>Paklikép hozzáadása</p>
          <button onClick={(e) => handleClose()}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="image-modal-body">
          <div className="image-modal-grid-container">
            {firabaseImages &&
              firabaseImages.map((item, index) => (
                <div
                  key={item}
                  onClick={() => setid(item)}
                  className="modal-div"
                >
                  <ModalImage url={item} selectedId={selectedId} />
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="image-modal-footer">
          <div className="image-modal-buton-holder">
            <button
              onClick={() => cancelUpload()}
              className="image-modal-close"
            >
              Mégsem
            </button>
            <button
              onClick={isModal ? addChangeImageUrl : saveimage}
              className={
                !selectedId ? "image-modal-save disabled" : "image-modal-save"
              }
              disabled={!selectedId}
            >
              Mentés
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImageModal;
