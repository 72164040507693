export const constraints = {
  email: {
    presence: true,
    email: true,
    format: {
      pattern: "[^<>{}()]+",
      message: "Helytelen karakter használat",
    },
  },
  password: {
    presence: true,
    format: {
      pattern: "[^<>{}()]+",
      message: "Helytelen karakter használat",
    },
  },
};
