import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setprogress, toggleModal } from "../../utils/reducer";
import ReactModal from "./Modal";
import TableRow from "./tableRow";
import TableHead from "./tablehead";
import AddedRows from "./addedRows";
import HeaderBalls from "../../shared/header-balls";
import { resetPartial } from "../../utils/utilities/utility.functions";
import { deleteAndNavigate, lang } from "../../utils/utilities/error.values";
import { addmodalvalues } from "../../utils/reducer";
const Uploadtable = () => {
  const navigate = useNavigate();
  const modalState = useSelector((state) => state.model.displayModal);
  const imageModal = useSelector((state) => state.model.imagesModal);
  const rows = useSelector((state) => state.model.rowCount);
  const { exelUpload, langOne, langTwo } = useSelector((state) => state.model);
  const { upload, completed } = useSelector((state) => state.model.procceses);
  const dispatch = useDispatch();

  const [show, setSwow] = useState(false);

  // const pagination = useMemo(() => {
  //   const range = () => {
  //     let value =
  //       state.length > count
  //         ? state.slice(1, count)
  //         : state.slice(1, state.length);
  //     return value;
  //   };
  //   return range;
  // }, [count, state]);
  const back = () => {
    if (exelUpload) {
      getError(deleteAndNavigate);
    } else {
      resetPartial(dispatch);
      navigate("/uploadfile");
    }
  };

  const deleteAllExel = () => {
    resetPartial(dispatch);
    navigate("/uploadfile");
  };
  const closeModal = () => {
    return new Promise((resolve, reject) => {
      resolve(
        setTimeout(() => {
          setSwow(false);
        }, 500)
      );
    }).then(() => {
      dispatch(toggleModal(false));
    });
  };
  useEffect(() => {
    if (!upload || completed) {
      resetPartial(dispatch);
      navigate("/uploadfile");
    }
  }, [upload, completed, dispatch]);
  const getError = (error) => {
    dispatch(addmodalvalues(error));
    dispatch(toggleModal(true));
    setSwow(true);
  };
  const doneAndNavigate = () => {
    if (exelUpload) {
      if (!langOne || !langTwo) {
        return getError(lang);
      }
      dispatch(setprogress("check"));
      navigate("/image");
    }
    return;
  };
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div className="header-balls active">
        <HeaderBalls />
      </div>
      <div
        className={
          imageModal || modalState ? "upwith-cont modal" : "upwith-cont"
        }
      >
        <div className="fl-without">
          <div style={{ display: "none" }}>
            <ReactModal
              handleClose={closeModal}
              show={show}
              action={deleteAllExel}
            />
          </div>
          <div>
            <div
              className={
                !modalState && !imageModal ? "vis table-controll" : "hidden-vis"
              }
            >
              <div className="main-head">
                <TableHead state={exelUpload} rows={rows} isModal={false} />
              </div>
              <div className="main-float">
                <div className="main-row">
                  {exelUpload &&
                    exelUpload.map((items) => (
                      <div
                        key={items.did}
                        className="border-none table-top w-full flex"
                        style={{ maxHeight: "300px" }}
                      >
                        <TableRow
                          items={items}
                          modalState={modalState}
                          state={exelUpload}
                          imageModal={imageModal}
                        />
                      </div>
                    ))}
                  <div style={{ width: "100%" }}>
                    {rows && rows.length > 0 && <AddedRows rows={rows} />}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={!isModal ? "main-foot" : "main-foot active"}>
              <TableFooter setSwow={setSwow} isModal={false} />
            </div> */}
            <div
              className={
                modalState || imageModal ? "hidden-vis" : "file-footer"
              }
            >
              <button className="footer-button" onClick={back}>
                Vissza
              </button>
              <button
                onClick={doneAndNavigate}
                className={
                  !exelUpload
                    ? "footer-button disabled"
                    : "footer-button active"
                }
              >
                következő
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Uploadtable);
