import { resetAllState, resetPartialState } from "../../utils/reducer";
import { resetPakli } from "../../utils/pakli-reducer";
import { deletePakli, resetAllUpload } from "../updatemodel";
import { resetUiState } from "../ui-reducer";
export const resetAll = (dispatch) => {
  dispatch(resetPakli());
  dispatch(resetUiState());
  dispatch(resetAllUpload());
  dispatch(resetAllState());
};
export const resetPartial = (dispatch) => {
  dispatch(resetPakli());
  dispatch(resetUiState());
  dispatch(resetAllUpload());
  dispatch(resetPartialState());
};
const baseUrl =
  process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
export const deletePakliFromDB = async (pid, dispatch) => {
  try {
    const respones = await fetch(`${baseUrl}delete_stack?pid=${pid}`, {
      method: "DELETE",
      credentials: "include",
    });
    if (respones.status === 200) {
      dispatch(deletePakli(pid));
    }
  } catch (e) {
    console.log(e);
  }
};
