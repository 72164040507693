import {
  sendEmailVerification,
  getAuth,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { app } from "../utils/firebase";
export const createUser = async (
  email,
  password,
  birthday,
  name,
  fn,
  capChaRef,
  errorState
) => {
  const auth = getAuth();
  try {
    await capChaRef.current.execute();
    if (errorState.errors.regError) {
      return;
    }
    const credential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const baseUrl =
      process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
    const { user } = credential;
    await sendEmailVerification(auth.currentUser);
    const token = await user.getIdToken();
    const response = await fetch(`${baseUrl}registration`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user.email,
        birthday,
        name,
      }),
    });
    if (response.status !== 200 || response.status !== 201) {
      return fn({ type: "regError", payload: "Hiba a regisztráció során!" });
    } else {
      return fn({
        type: "regError",
        payload: "Az igazoló email.-t elküldtük!",
      });
    }
  } catch (err) {
    const errorMessage =
      err.code === "auth/email-already-in-use"
        ? "Ezzel az Email cimmel már van fiók regisztrálva"
        : "Hiba a regisztráció során";
    fn({
      type: "regError",
      payload: errorMessage,
    });
  }
};
