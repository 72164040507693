import React, { useState, useEffect } from "react";
import TableHead from "./tablehead";
import AddedRows from "./addedRows";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import HeaderBalls from "../../shared/header-balls";
import { resetPartial } from "../../utils/utilities/utility.functions";
import {
  toggleModal,
  resetModalvalues,
  addmodalvalues,
  setprogress,
} from "../../utils/reducer";
import { addheaderLine } from "../../utils/ui-reducer";
import ReactModal from "../UI/Modal";
import { useNavigate } from "react-router-dom";
import { deleteAndNavigate, lang } from "../../utils/utilities/error.values";
const UploadWithout = () => {
  const [show, setSwow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.model.exelUpload);
  const rows = useSelector((state) => state.model.rowCount);
  const { langOne, langTwo } = useSelector((state) => state.model);
  const modalStatus = useSelector((state) => state.model.imagesModal);
  const statusModal = useSelector((state) => state.model.displayModal);
  const { completed } = useSelector((state) => state.model.procceses);

  useEffect(() => {
    if (completed) {
      resetPartial(dispatch);
      navigate("/uploadfile");
    }
  }, [completed]);
  const closeModal = () => {
    setSwow(false);
    dispatch(toggleModal(false));
    dispatch(resetModalvalues());
  };
  const deleteAllExel = () => {
    resetPartial(dispatch);
    closeModal();
    navigate("/uploadfile");
  };
  const getError = (error) => {
    dispatch(addmodalvalues(error));
    dispatch(toggleModal(true));
    setSwow(true);
  };
  const doneAndNavigate = () => {
    if (rows.length >= 1) {
      if (!langOne || !langTwo) {
        return getError(lang);
      }
      dispatch(
        addheaderLine({
          values: {
            lineOne: "active",
            lineTwo: "",
          },
        })
      );
      dispatch(setprogress("upload"));
      dispatch(setprogress("check"));
      navigate("/image");
    }
    return;
  };
  const back = () => {
    if (rows.length > 1) {
      getError(deleteAndNavigate);
    } else {
      resetPartial(dispatch);
      navigate("/uploadfile");
    }
  };
  return (
    <div style={{ height: "100vh" }}>
      <div className="header-balls active">
        <HeaderBalls />
      </div>
      <div
        style={{
          width: "100vw",
          position: "relative",
        }}
      >
        {/* <div className="upl-img-holder">
          <ImageUpload isModal={true} />
        </div> */}

        <ReactModal
          handleClose={closeModal}
          show={show}
          action={deleteAllExel}
        />
        <section className="upwith-cont">
          <div className="fl-without">
            <div className={modalStatus || statusModal ? "hidden-vis" : "rela"}>
              <div className="no-upload-table">
                <TableHead rows={rows} state={state} />
                <div className="no-container">
                  <AddedRows rows={rows} />
                </div>
              </div>
              <div
                className={
                  modalStatus || statusModal ? "hidden-vis" : "file-footer"
                }
              >
                <button className="footer-button" onClick={() => {}}>
                  Vissza
                </button>
                <button
                  onClick={doneAndNavigate}
                  className={
                    rows && rows.length > 1
                      ? "footer-button active"
                      : "footer-button disabled"
                  }
                >
                  következő
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UploadWithout;
