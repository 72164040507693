import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pakliName: null,
  pakliImage: null,
  pakliCode: null,
  resetPakli: null,
};

const PakliModel = createSlice({
  name: "Pakli",
  initialState,
  reducers: {
    addPakliName(state, action) {
      state.pakliName = action.payload;
    },
    removePakliName(state) {
      state.pakliName = null;
    },
    addImageId(state, action) {
      state.pakliImage = action.payload;
    },
    removeImage(state) {
      state.pakliImage = null;
    },
    setPaklicode(state, action) {
      state.pakliCode = action.payload;
    },
    resetPakli: () => initialState,
    addResetPakli(state, action) {
      state.resetPakli = action.payload;
    },
  },
});

export const {
  addPakliName,
  removePakliName,
  addImageId,
  removeImage,
  setPaklicode,
  resetPakli,
  addResetPakli,
} = PakliModel.actions;
export default PakliModel.reducer;
