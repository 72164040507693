import { FacebookAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
const provider = new FacebookAuthProvider();
export const facebookSignIn = async (
  errorDispatch,
  setDispatch,
  setUser,
  navigate
) => {
  const auth = getAuth();
  try {
    const { user } = await signInWithPopup(auth, provider);
    const baseUrl =
      process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
    const token = await user.getIdToken();
    const response = await fetch(`${baseUrl}sign_in`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        name: user.displayName,
        email: user.email,
        birthday: null,
      }),

      headers: {
        Authorization: "Bearer " + token,

        "Content-Type": "application/json",
      },
    });
    if (response.status === 200 || response.status === 201) {
      setDispatch(setUser(true));
      navigate("/uploadfile");
    }
  } catch (err) {
    errorDispatch({
      type: "regError",
      payload: "Hiba a belépés során!!",
    });
  }
};
