import React, { useState } from "react";
import { navilinks } from "../utils/routes";
import Menulink from "../utils/menulink";
import { useSelector } from "react-redux";
const Navigation = () => {
  const [open, setOpen] = useState(true);
  const { user } = useSelector((state) => state.user);
  const nlinks = user
    ? navilinks
        .filter((items) => items.protected === true)
        .sort((a, b) => b.id < a.id)
    : navilinks.filter((items) => items.protected === false);
  return (
    <div className={open ? "top-nav-menu open" : "top-nav-menu"}>
      <li className="nav-logo-t">Tongie</li>

      <div className="link-holder">
        <ul
          className={
            !open ? "nav-holder-container" : "nav-holder-container open"
          }
        >
          {nlinks.map((route) => (
            <Menulink key={route.id} text={route.text} path={route.path} />
          ))}
        </ul>
      </div>

      <span
        className={!open ? "nav-toogle" : "nav-toogle open"}
        onClick={() => setOpen(!open)}
      >
        <span className={open ? "nav-line f-line" : "nav-line"}></span>
        <span className={!open ? "nav-line" : "visHid"}></span>
        <span className={open ? "nav-line s-line" : "nav-line"}></span>
      </span>
    </div>
  );
};
export default Navigation;
