import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pakliwords: null,
  isModal: false,
  upload: null,
  deleteId: null,
  rows: null,
  langOne: null,
  isUpdate: true,
  langTwo: null,
  pakliUpload: {
    pakliName: null,
    pakliImage: null,
    pakliCode: null,
  },
};

export const UploadModel = createSlice({
  name: "Upload",
  initialState,
  reducers: {
    setPakliwords(state, action) {
      state.pakliwords = action.payload;
    },
    setDeleteId(state, action) {
      state.deleteId = action.payload;
    },
    deletePakli(state, action) {
      let coppyWords = [...state.pakliwords];
      const stateUpdated = coppyWords.filter((el) => el.pid !== action.payload);
      state.pakliwords = stateUpdated;
    },
    addUpload(state, action) {
      state.upload = action.payload;
    },
    removeUploadItem(state, action) {
      let coppyArray = [...state.rows];
      let updated = coppyArray.filter((item) => item.did !== action.payload);
      state.rows = updated;
    },

    modifyUploadItem(state, action) {
      let { index, item } = action.payload;
      let copyState = [...state.upload];
      copyState[index] = item;
      return {
        ...state,
        upload: copyState,
      };
    },
    modifyOne(state, action) {
      let { item, did } = action.payload;
      const indexEdItem = state.rows.findIndex((el) => el.did == did);
      const it = state.rows.find((el) => el.did === did);
      state.rows = [
        ...state.rows.slice(0, indexEdItem),
        { lang_one: item, lang_two: it.lang_two, did: it.did },
        ...state.rows.slice(indexEdItem + 1),
      ];
    },
    modifyTwo(state, action) {
      let { item, did } = action.payload;
      const indexEdItem = state.rows.findIndex((el) => el.did == did);
      const it = state.rows.find((el) => el.did === did);
      state.rows = [
        ...state.rows.slice(0, indexEdItem),
        { lang_one: it.lang_one, lang_two: item, did: it.did },
        ...state.rows.slice(indexEdItem + 1),
      ];
    },
    addLangueOne(state, action) {
      state.langOne = action.payload;
    },
    addLangueTwo(state, action) {
      state.langTwo = action.payload;
    },

    addNewRowUpdated(state, action) {
      const index = state.rows.findIndex(
        (el) => el.lang_one === "" && el.lang_two === ""
      );
      if (index > -1) {
        return;
      } else {
        state.rows.push({
          lang_one: "",
          lang_two: "",
          did: action.payload,
        });
      }
    },
    deleteUpImage(state) {
      state.pakliUpload.pakliImage = null;
    },
    deleteUpname(state) {
      state.pakliUpload.pakliName = null;
    },
    changeUpName(state, action) {
      state.pakliUpload.pakliName = action.payload;
    },
    changeUpImage(state, action) {
      state.pakliUpload.pakliImage = action.payload;
    },

    addUploadPakli(state, action) {
      state.pakliUpload = action.payload;
    },
    resetUploadPakli(state) {
      state.pakliUpload = initialState.pakli;
    },
    addUpdateRow(state, action) {
      state.rows = action.payload;
    },
    resetAllUpload: () => initialState,
    setIsupdate(state, action) {
      state.isUpdate = action.payload;
    },
    setIsModal(state, action) {
      state.isModal = action.payload;
    },
  },
});
export const {
  setDeleteId,
  deletePakli,
  changeUpImage,
  resetUploadPakli,
  addUpload,
  addUploadNewItem,
  addUploadPakli,
  modifyUploadItem,
  removeUploadItem,
  deleteUpImage,
  deleteUpname,
  changeUpName,
  addUpdateRow,
  modifyTwo,
  modifyOne,
  addNewRowUpdated,
  addLangueOne,
  addLangueTwo,
  resetAllUpload,
  setPakliwords,
  setIsupdate,
  setIsModal,
} = UploadModel.actions;
export default UploadModel.reducer;
