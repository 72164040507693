import { configureStore } from "@reduxjs/toolkit";
import MainModel from "./reducer";
import pakliReducer from "./pakli-reducer";
import UiModel from "./ui-reducer";
import UploadModel from "./updatemodel";
import MainUserModel from "./user.reducer";

export const store = configureStore({
  // devTools: process.env.NODE_ENV !== "production" ? true : false,
  reducer: {
    model: MainModel,
    pakli: pakliReducer,
    ui: UiModel,
    update: UploadModel,
    user: MainUserModel,
  },
});
