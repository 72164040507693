export const nameImage = {
  message: [`Elfelejtetél képet hozzáadni!`],
  title: "Emlékeztetö",
  buttonFirst: "",
  buttonSec: "Rendben",
  isDouble: false,
  isAction: false,
};
export const name = {
  message: [`Elfelejtetél pakli nevet hozzáadni!`],
  title: "Emlékeztetö",
  buttonFirst: "",
  buttonSec: "Rendben",
  isDouble: false,
  isAction: false,
};
export const lang = {
  message: [`Elfelejtetél nyelvet kiválasztani!`],
  title: "Emlékeztetö",
  buttonFirst: "",
  buttonSec: "Rendben",
  isDouble: false,
  isAction: false,
};
export const deletePakli = {
  isAction: true,
  message: [
    "A törlés gombal a paklid törlésre kerül!",
    "Biztos vagy benne, hogy törlöd a paklit?",
  ],
  title: "Figyelmeztetés",
  buttonFirst: " Mégsem",
  buttonSec: "Törlés",
  isDouble: true,
};
export const deleteAndNavigate = {
  isAction: true,
  message: [
    "A vissza gombal a paklid törlésre kerül!",
    "Biztos vagy benne, hogy elhagyod az oldalt?",
  ],
  title: "Figyelmeztetés",
  buttonFirst: " Mégsem",
  buttonSec: "Rendben",
  isDouble: true,
};
