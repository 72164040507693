import { useSelector } from "react-redux";
const Share = () => {
  const state = useSelector((state) => state.model.procceses);
  return (
    <div className={state.image ? `icon-holder active` : "icon-holder "}>
      <div className="image-global">
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 663 663"
          fill="#111"
        >
          <g>
            <g id="_x35__37_">
              <g>
                <path
                  d="M510,408c-45.135,0-84.558,23.588-107.202,58.956l-136.527-78.03c8.797-17.34,14.229-36.644,14.229-57.426
				c0-12.827-2.474-24.964-5.993-36.644l142.061-81.167C439.85,238.935,472.948,255,510,255c70.405,0,127.5-57.095,127.5-127.5
				S580.405,0,510,0S382.5,57.095,382.5,127.5c0,12.827,2.474,24.964,5.992,36.644l-142.06,81.192
				C223.15,220.065,190.051,204,153,204c-70.405,0-127.5,57.069-127.5,127.5C25.5,401.905,82.595,459,153,459
				c29.07,0,55.539-10.098,76.984-26.494l153.893,89.198c-0.484,4.616-1.377,9.078-1.377,13.796c0,70.405,57.095,127.5,127.5,127.5
				s127.5-57.095,127.5-127.5S580.405,408,510,408z"
                />
              </g>
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </div>
    </div>
  );
};
export default Share;
