import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageUploadImage from "./imageUploadImage";
import { addPakliName, removeImage } from "../../utils/pakli-reducer";
import { toogleImageModal } from "../../utils/reducer";
import ImageModal from "./imageModal";
import Draw from "../utility/utility-icons/draw";
import Bin from "../utility/utility-icons/bin";
import { deleteUpImage, changeUpName } from "../../utils/updatemodel";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { addFirebaseImages } from "../../utils/reducer";

const ImageUpload = ({ isModal }) => {
  const firabaseImages = useSelector((state) => state.model.firabaseImages);
  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      if (Array.isArray(firabaseImages) && firabaseImages.length > 0) return;
      const storage = getStorage();
      const listref = ref(storage, "/images");
      const list = await listAll(listref);
      const imageFolder = list.items.forEach(async (item) => {
        const url = await getDownloadURL(item);
        dispatch(addFirebaseImages(url));
      });
    })();
    return () => controller.abort();
  }, [firabaseImages]);

  const [pakli, setPakli] = useState(null);
  const [isPakli, setisPakli] = useState(false);

  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.model.imagesModal);
  const displayModal = useSelector((state) => state.model.displayModal);
  const [selectedId, setid] = useState(null);

  const { pakliImage, pakliName } = !isModal
    ? useSelector((state) => state.pakli)
    : useSelector((state) => state.update.pakliUpload);

  const url = pakliImage && pakliImage;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    return new Promise((resolve, reject) => {
      resolve(setid(null));
    })
      .then(() => dispatch(toogleImageModal(false)))
      .then(() => {
        setTimeout(() => {
          setShow(false);
        }, 500);
      });
  };
  const modalOpen = () => {
    setShow(true);
    dispatch(toogleImageModal(true));
  };
  const addpakli = () => {
    if (!pakli) return;
    dispatch(addPakliName(pakli));
    return setPakli(null);
  };

  const deleteImage = () => {
    dispatch(removeImage());
  };
  const deleteUploadImage = () => {
    dispatch(deleteUpImage());
  };
  const addChangedPakliName = () => {
    dispatch(changeUpName(pakli));
  };

  return (
    <Fragment>
      <div style={{ display: "none", width: "0px", height: "0px" }}>
        <ImageModal
          show={show}
          handleClose={handleClose}
          selectedId={selectedId}
          setid={setid}
          isModal={isModal}
        />
      </div>
      <div
        className={
          modalState || displayModal ? "images-cover active" : "images-cover"
        }
      >
        {!pakliImage ? (
          <div
            className={
              !modalState && !displayModal
                ? "photo-image"
                : "photo-image active"
            }
            onClick={modalOpen}
          >
            <ImageUploadImage />
            <p>Fénykép</p>
            <p>hozzáadása</p>
          </div>
        ) : (
          <div className="pakli-image-container">
            <div className="pakli-image-inner">
              <img src={url && url} alt="pakli kep" />
              <div className="pakli-button-holder">
                <button
                  className="pakli-button"
                  onClick={modalOpen}
                  // style={{ marginBottom: 16 }}
                >
                  <Draw color="#541187" />
                </button>
                <button
                  className="pakli-button"
                  onClick={!isModal ? deleteImage : deleteUploadImage}
                >
                  <Bin width="20px" color="#871111" />
                </button>
              </div>
            </div>
          </div>
        )}
        <p
          className="para"
          style={{ fontFamily: "PoetsenOne", textAlign: "center" }}
        >
          A Te Paklid címe{pakliName && <span>: {pakliName}</span>}
        </p>
        <input
          type="text"
          placeholder="Pakli címe"
          onChange={(e) => setPakli(e.target.value)}
          onFocus={() => setisPakli(true)}
          disabled={modalState}
          value={pakli || ""}
        />
        {isPakli && (
          <button
            disabled={modalState}
            onClick={!isModal ? addpakli : addChangedPakliName}
            style={{ fontFamily: "PoetsenOne-Regular" }}
            className="adderBtn"
          >
            {!pakliName
              ? "Ad meg a pakli nevét!"
              : "Valtoztasd meg a pakli nevét"}
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default ImageUpload;
