import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDJ7wc6Nqcw6xtlqideqPviKDBMrRyH-sM",
  authDomain: "tongie-auth-dev.firebaseapp.com",
  projectId: "tongie-auth-dev",
  storageBucket: "tongie-auth-dev.appspot.com",
  messagingSenderId: "11890475390",
  appId: "1:11890475390:web:8b7736a5e07f73c9c9732f",
  measurementId: "G-NYVGQ8YWZB",
};
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
