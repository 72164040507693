import Info from "../utility/utility-icons/info";

const Center = () => {
  return (
    <div className="coverDiv">
      <div className="cover-tooltip">
        <div className="tooltip-top">
          <div className="tooltiptext">Táblázat feltöltése</div>
          <Info color="#A7A7A7" />
        </div>
      </div>
      <div className="text-div">
        <h6>Húzd a téglalapba a *.xlsx, *.xls, *.csv file-odat</h6>
        <p>vagy válassz az alábbi opciók közül</p>
      </div>
      <div className="inner-center">
        <div className="btn active">
          <label htmlFor="inputfield" style={{ textAlign: "center" }}>
            Táblázat feltöltése
          </label>
        </div>
      </div>
    </div>
  );
};

export default Center;
