import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Close from "../utility/utility-icons/closeButton";
const ReactModal = ({ action, handleClose, show }) => {
  const { message, title, buttonFirst, buttonSec, isDouble, isAction } =
    useSelector((state) => state.model.modalValues);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="image-modal-container react-modal-top"
      >
        <Modal.Header
          closeButton
          className="image-modal-header react-modal-header"
        >
          <p>{title}</p>
          <button onClick={handleClose}>
            <Close />
          </button>
          <span className="modal-line"></span>
        </Modal.Header>
        <Modal.Body className="react-modal-body">
          {message && message.map((mes) => <p key={mes}>{mes}</p>)}
        </Modal.Body>
        <Modal.Footer className="image-modal-footer react-modal-footer">
          {isDouble && (
            <button className="image-modal-close" onClick={() => handleClose()}>
              {buttonFirst}
            </button>
          )}

          <button
            onClick={isAction ? action : handleClose}
            className="image-modal-save"
          >
            {buttonSec}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ReactModal;
