import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exelUpload: null,
  langOne: null,
  langTwo: null,
  newCode: null,
  pakliCode: null,
  procceses: {
    share: false,
    upload: false,
    check: false,
    image: false,
    without: false,
    completed: false,
  },
  displayModal: false,
  imagesModal: false,
  modalValues: {
    message: null,
    title: null,
    buttonFirst: null,
    buttonSec: null,
    isDouble: false,
    isAction: false,
  },
  rowCount: [{ lang_one: "", lang_two: "", did: new Date().toString() }],

  rowChange: false,
  firabaseImages: [],
};

export const MainModel = createSlice({
  name: "Main",
  initialState,

  reducers: {
    resetLang(state) {
      state.langOne = null;
      state.langTwo = null;
    },
    resetProcces(state) {
      state.procceses = initialState.procceses;
    },
    setNewCode(state, action) {
      state.newCode = action.payload;
    },
    addExel(state, action) {
      state.exelUpload = action.payload;
    },
    addLangOne(state, action) {
      state.langOne = action.payload;
    },
    addLangTwo(state, action) {
      state.langTwo = action.payload;
    },
    addFirebaseImages(state, action) {
      state.firabaseImages.push(action.payload);
    },

    toggleProgress(state, action) {
      let { value, progress } = action.payload;
      const copyState = state.procceses;
      progress.map((elem) => {
        copyState[elem] = value;
      });
      state.procceses = copyState;
    },
    setprogress(state, action) {
      let { did } = action.payload;
      return {
        ...state,
        procceses: {
          ...state.procceses,
          [action.payload]: true,
        },
      };
    },
    removeItem(state, action) {
      let coppyArray = [...state.exelUpload];
      let updated = coppyArray.filter((item) => item.did !== action.payload);
      state.exelUpload = updated;
    },
    modifyItemOne(state, action) {
      let { item, did } = action.payload;
      const indexEdItem = state.exelUpload.findIndex((el) => el.did == did);
      const it = state.exelUpload.find((el) => el.did === did);
      state.exelUpload = [
        ...state.exelUpload.slice(0, indexEdItem),
        { lang_one: item, lang_two: it.lang_two, did: it.did },
        ...state.exelUpload.slice(indexEdItem + 1),
      ];
    },
    modifyItTwo(state, action) {
      let { item, did } = action.payload;
      const indexEdItem = state.exelUpload.findIndex((el) => el.did == did);
      const it = state.exelUpload.find((el) => el.did === did);
      state.exelUpload = [
        ...state.exelUpload.slice(0, indexEdItem),
        { lang_one: it.lang_one, lang_two: item, did: it.did },
        ...state.exelUpload.slice(indexEdItem + 1),
      ];
    },
    toggleModal(state, action) {
      state.displayModal = action.payload;
    },
    toogleImageModal(state, action) {
      state.imagesModal = action.payload;
    },

    addmodalvalues(state, action) {
      let { message, title, isDouble, buttonFirst, buttonSec, isAction } =
        action.payload;
      return {
        ...state,
        modalValues: {
          ...state.modalValues,
          title,
          message,
          buttonFirst,
          buttonSec,
          isDouble,
          isAction,
        },
      };
    },
    resetPartialState(state) {
      Object.assign(state, {
        firabaseImages: [...state.firabaseImages],
        ...initialState,
      });
    },

    resetState(state) {
      state.exelUpload = null;
    },
    resetAllState: () => initialState,

    deleteAllRow(state) {
      state.rowCount = initialState.rowCount;
    },
    removeRow(state, action) {
      const item = state.rowCount.find((el) => el.did === action.payload);
      if (item.lang_one === "" || item.lang_two === "") {
        return;
      } else {
        const coppyState = state.rowCount.filter(
          (el) => el.did !== action.payload
        );
        state.rowCount = coppyState;
      }
    },
    resetModalvalues(state) {
      state.modalValues = initialState.modalValues;
    },
    addFirstRow(state, action) {
      let { item, did } = action.payload;
      const indexEdItem = state.rowCount.findIndex((el) => el.did == did);
      const it = state.rowCount.find((el) => el.did === did);
      state.rowCount = [
        ...state.rowCount.slice(0, indexEdItem),
        { lang_one: item, lang_two: it.lang_two, did: it.did },
        ...state.rowCount.slice(indexEdItem + 1),
      ];
    },
    addSecondRow(state, action) {
      let { item, did } = action.payload;
      const indexEdItem = state.rowCount.findIndex((el) => el.did == did);
      const it = state.rowCount.find((el) => el.did === did);
      state.rowCount = [
        ...state.rowCount.slice(0, indexEdItem),
        { lang_one: it.lang_one, lang_two: item, did: it.did },
        ...state.rowCount.slice(indexEdItem + 1),
      ];
    },
    addNewRow(state, action) {
      const index = state.rowCount.findIndex(
        (el) => el.lang_one === "" && el.lang_two === ""
      );
      if (index > -1) {
        return;
      } else {
        state.rowCount.push({
          lang_one: "",
          lang_two: "",
          did: action.payload,
        });
      }
    },
  },
});

export const {
  resetPartialState,
  resetAllState,
  addExel,
  addFirebaseImages,
  deleteAllRow,
  setprogress,
  toggleModal,
  addmodalvalues,
  resetState,
  removeItem,
  addNewItem,
  modifyItemOne,
  modifyItTwo,
  removeRow,
  addNewRow,
  toogleImageModal,
  toggleProgress,
  resetModalvalues,
  addLangOne,
  addLangTwo,
  addFirstRow,
  addSecondRow,
  resetProcces,
  resetLang,
} = MainModel.actions;
export default MainModel.reducer;
