import Paklik from "../pages/paklik";
import UploadFile from "../components/upload/uploadfile";
import PakliChange from "../pages/pakliChange";
import About from "../pages/about";
import Contact from "../pages/contact";
import Policy from "../pages/policy";
import SingUp from "../pages/signup";
import Login from "../pages/login";
import Dataprivacy from "../pages/dataprivacy";
import SignOut from "../pages/signout";
import Impressum from "../pages/impressum";
import Recovery from "../pages/recovery";
import UploadWithout from "../components/UI/uploadWithout";
import Uploadtable from "../components/UI/uploadtable";
import FinisHedUpload from "../components/UI/finishedUpload";
import ImageUpload from "../components/UI/image-upload";
import ImageuploadPage from "../pages/imageupload";

export const approutes = [
  {
    id: 3,
    component: <Paklik />,
    path: "/paklik",
    protected: true,
  },
  {
    id: 1,
    component: <UploadFile />,
    path: "/home",
    protected: true,
  },
  {
    id: 4,
    component: <PakliChange />,
    path: "/paklik/:id",
    protected: true,
  },

  {
    id: 6,
    component: <UploadFile />,
    path: "/uploadfile",
    protected: true,
  },

  {
    id: 9,
    component: <SingUp />,
    path: "/signup",
    protected: false,
  },
  {
    id: 12,
    component: <SignOut />,
    path: "/signout",
    protected: true,
  },

  {
    id: 15,
    component: <UploadWithout />,
    path: "/upwithout",
    protected: true,
  },
  {
    id: 16,
    component: <Uploadtable />,
    path: "/uploaded",
    protected: true,
  },
  {
    id: 17,
    component: <FinisHedUpload />,
    path: "/done/:pid",
    protected: true,
  },
  {
    id: 18,
    component: <ImageuploadPage />,
    path: "/image",
    protected: true,
  },
];
export const navilinks = [
  {
    id: 4,
    path: "/",
    protected: false,
    text: "Bejelentkezés",
  },
  {
    id: 5,
    path: "/signup",
    text: "Regisztráció",
    protected: false,
  },
  // {
  //   id: 1,
  //   path: "/home",
  //   text: "Főoldal",
  //   protected: true,
  // },
  {
    id: 1,
    path: "/uploadfile",
    text: "Pakli létrehozása",
    protected: true,
  },
  {
    id: 2,
    path: "/paklik",
    text: "Paklik",
    protected: true,
  },
  {
    id: 3,
    path: "/signout",
    text: "kijelentkezés",
    protected: true,
  },
];

// Kapcsolat Rólunk Impresszum Adatvédelmi tájékoztató Felhasználási feltételek

export const footerLinks = [
  {
    id: 1,
    path: "/contact",
    text: "Kapcsolat",
  },
  {
    id: 2,
    path: "/about",
    text: "Rólunk",
  },
  {
    id: 3,
    path: "/impressum",
    text: "Impresszum",
  },
  {
    id: 4,
    path: "/privacy",
    text: "Adatvédelmi tájékoztató",
  },
  {
    id: 5,
    path: "/policy",
    text: "Felhasználási feltételek",
  },
];

export const common = [
  {
    id: 13,
    component: <Impressum />,
    path: "/impressum",
    protected: false,
  },
  {
    id: 14,
    component: <Recovery />,
    path: "/recovery",
    protected: false,
  },
  {
    id: 5,
    component: <About />,
    path: "/about",
    protected: false,
  },
  {
    id: 7,
    component: <Contact />,
    path: "/contact",
    protected: false,
  },
  {
    id: 8,
    component: <Policy />,
    path: "/policy",
    protected: false,
  },
  {
    id: 11,
    component: <Dataprivacy />,
    path: "/privacy",
    protected: false,
  },
];
