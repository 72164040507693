import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderBalls from "../shared/header-balls";
import { useParams } from "react-router-dom";
import {
  addUploadPakli,
  addUpload,
  addUpdateRow,
  addLangueOne,
  addLangueTwo,
} from "../utils/updatemodel";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import TableHead from "../components/UI/tablehead";
import AddedRows from "../components/UI/addedRows";
import { v4 as uuidv4 } from "uuid";
import ReactModal from "../components/UI/Modal";
import { useNavigate } from "react-router-dom";
import { resetProcces, setprogress, toggleModal } from "../utils/reducer";
import { resetAll } from "../utils/utilities/utility.functions";
import { resetAllUpload } from "../utils/updatemodel";

const PakliChange = () => {
  const navigate = useNavigate();
  const [show, setSwow] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const modalStatus = useSelector((state) => state.model.imagesModal);
  const statusModal = useSelector((state) => state.model.displayModal);
  const rows = useSelector((state) => state.update.rows);
  const { completed } = useSelector((state) => state.model.procceses);

  useEffect(() => {
    if (completed) {
      resetAll(dispatch);
      dispatch(resetAllUpload());
    }
  }, [dispatch]);
  const closeModal = () => {
    return new Promise((resolve, reject) => {
      resolve(
        setTimeout(() => {
          setSwow(false);
        }, 500)
      );
    }).then(() => {
      dispatch(toggleModal(false));
      setSwow(false);
    });
  };
  const resetUpdate = () => {
    closeModal();
    navigate("/uploadfile");
  };
  const baseUrl =
    process.env.NODE_ENV !== "dev" ? "https://api.tongie.app/v1/" : "/";
  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      const response = await fetch(`${baseUrl}words/${params.id}`, {
        method: "GET",
        credentials: "include",
      });
      if (response.status === 200) {
        const pakli = await response.json();
        const storage = getStorage();
        const imageRef = ref(storage, pakli.image_url);
        const dDownUrl = await getDownloadURL(imageRef);
        dispatch(addUpload(pakli));
        dispatch(addLangueOne(pakli.lang_1));
        dispatch(addLangueTwo(pakli.lang_2));
        const parliRows = pakli.words.map((el) => ({
          lang_one: el.lang_1,
          lang_two: el.lang_2,
          did: uuidv4(),
          ...el,
        }));
        dispatch(addUpdateRow(parliRows));
        dispatch(
          addUploadPakli({
            pakliName: pakli.stack_name,
            pakliCode: pakli.pid,
            pakliImage: dDownUrl,
          })
        );
      }
    })();
    return () => controller.abort();
  }, [dispatch]);
  const goBack = () => {
    dispatch(resetProcces());
    navigate("/paklik");
  };
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <div className="header-balls active">
        <HeaderBalls />
      </div>
      <div
        style={{
          width: "100vw",
          position: "relative",
        }}
      >
        <section
          className={
            modalStatus || statusModal ? "upwith-cont modal" : "upwith-cont"
          }
        >
          <div className="fl-without">
            <div style={{ display: "none" }}>
              <ReactModal
                handleClose={closeModal}
                show={show}
                action={resetUpdate}
              />
            </div>
            <div className={modalStatus || statusModal ? "hidden-vis" : "rela"}>
              <div className="no-upload-table">
                {rows && (
                  <TableHead rows={rows && rows} state={null} isModal={true} />
                )}
                <div className="no-container">
                  {rows && <AddedRows rows={rows} isModal={true} />}
                </div>
              </div>
              <div className="file-footer">
                <button className="footer-button" onClick={goBack}>
                  Vissza
                </button>
                <button
                  onClick={() => {
                    dispatch(setprogress("check"));
                    navigate("/image");
                  }}
                  className="footer-button active"
                >
                  következő
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PakliChange;
