import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contBorder: "",
  headerLines: {
    lineOne: "",
    lineTwo: "",
  },
};

const UiModel = createSlice({
  name: "ui-reducer",
  initialState,
  reducers: {
    resetUiState: () => initialState,
    addheaderLine(state, action) {
      const { values } = action.payload;
      state.headerLines = values;
    },
    resetLines(state) {
      state.headerLines = { lineOne: "", lineTwo: "" };
    },
    setBorder(state, action) {
      state.contBorder = action.payload;
    },
    resetBorder(state) {
      state.contBorder = initialState.contBorder;
    },
  },
});
export const {
  addheaderLine,
  resetLines,
  setBorder,
  resetBorder,
  resetUiState,
} = UiModel.actions;
export default UiModel.reducer;
